//UTILITIES
import { Card, CardContent, Skeleton } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "../../../context/AlertContext";
//COMPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import UsersExtendTrial from "./UsersExtendTrial";
//API
import { getUserById } from "../../../core/apis/user";

//CSS
import "./Users.scss";
import isDateExpired from "../../../core/functions/checkExpiryDate";
import { ErrorMessage } from "../../../core/variables/MessageVariables";



export function UsersDetail( props ) {
  //VARIABLES
  const [ openExtend, setOpenExtend ] = useState( false );
  const [ userDetail, setUserDetail ] = useState( null );
  const [ userUsage, setUserUsage ] = useState( {
    sessions: { used: 0, allowed: 0 },
    images: { used: 0, allowed: 0 },
  } );
  const [ loading, setLoading ] = useState( false );
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  const { id } = useParams();
  const [ checkStatus, setCheckStatus ] = useState( "" );

  //FUNCTIONS
  const GetData = () => {
    setLoading( true );
    getUserById( id ).then( ( res ) => {
      if ( res?.data?.success ) {
        let data = res?.data?.data;

        let sessions_allowed =
          res.data.data?.latest_subscription?.plan_subscription_items?.find(
            ( item ) => item?.item_slug === "created-sessions"
          );
        let images_allowed =
          res.data.data?.latest_subscription?.plan_subscription_items?.find(
            ( item ) => item?.item_slug === "uploaded-images"
          );

        setUserDetail( data );
        setUserUsage( {
          sessions: {
            used: res.data.data?.latest_subscription?.created_sessions_count ?? 0,
            allowed:
              sessions_allowed?.is_unlimited === 1
                ? -1
                : sessions_allowed?.value ?? 0,
          },
          images: {
            used: res.data.data?.latest_subscription?.created_images_count ?? 0,
            allowed:
              images_allowed?.is_unlimited === 1 ? -1 : images_allowed?.value ?? 0,
          },
        } );

        //registration only
        if ( !data?.latest_subscription ) {
          setCheckStatus( "Not Subscribed Yet" );
        }
        //on trial
        else if (
          data?.latest_subscription &&
          !data?.latest_subscription?.package_starts_at
        ) {
          if (
            data?.latest_subscription?.trial_starts_at &&
            isDateExpired( data?.latest_subscription?.trial_ends_at )
          ) {
            //if trial ends
            setCheckStatus( "Trial Expired" );
          } else {
            //if trial not ends
            setCheckStatus( "Trial" );
          }
        }
        //has taken a subsc
        else if ( data?.latest_subscription?.package_starts_at ) {
          if ( isDateExpired( data?.latest_subscription?.package_ends_at ) ) {
            //subsc expired
            setCheckStatus( "Expired" );
          } else {
            //subsc not expired
            setCheckStatus( "On Going" );
          }
        } else {
          setCheckStatus( "On Going" );
        }
      }
      setAlert( {
        text: res.data ? res.data.message : ErrorMessage,
        error: res?.data?.success ? false : true,
      } );
      setLoading( false );
    } );
  };
  useEffect( () => {
    GetData();
  }, [] );

  return (
    <>
      { !loading ? (
        <>
          <TitleComponent
            title={ `${ userDetail?.first_name + " " + userDetail?.last_name }` }
            classNames="page-title main-title"
            back
            handleBack={ () => navigate( -1 ) }
            handleEndAction={
              ( checkStatus === "Trial" || checkStatus === "Trial Expired" ) && (
                <div>
                  <PrimaryButton
                    text="Update Trial Period"
                    onClick={ () => setOpenExtend( true ) }
                  />
                </div>
              )
            }
          />
          <div className="subscriber-detail-component">
            <Card>
              <CardContent>
                <TitleComponent
                  title={ "Detail" }
                  classNames="page-title sub-title"
                />
                <div className="subscriber-info-detail">
                  <div className="subscriber-detail-row">
                    <div>Full Name</div>
                    <div>
                      { `${ userDetail?.first_name ? userDetail?.first_name : ""
                        } ${ userDetail?.last_name ? userDetail?.last_name : "" }` }
                    </div>
                  </div>

                  <div className="subscriber-detail-row">
                    <div>Company Name</div>
                    <div>
                      { userDetail?.company
                        ? userDetail?.company?.name
                        : "No Company" }
                    </div>
                  </div>

                  <div className="subscriber-detail-row">
                    <div>Email</div>
                    <div>{ userDetail?.email }</div>
                  </div>
                  <div className="subscriber-detail-row">
                    <div>Country</div>
                    <div>{ userDetail?.country?.name }</div>
                  </div>
                  <div className="subscriber-detail-row">
                    <div>Phone Number</div>
                    <div>{ userDetail?.phone_number }</div>
                  </div>
                  <div className="subscriber-detail-row">
                    <div>Fiscal Number</div>
                    <div>
                      { userDetail?.company
                        ? userDetail?.company?.fiscal_number
                          ? userDetail?.company?.fiscal_number
                          : "-"
                        : userDetail?.fiscal_number
                          ? userDetail?.fiscal_number
                          : "-" }
                    </div>
                  </div>
                  <div className="subscriber-detail-row">
                    <div>Registration Date</div>
                    <div>
                      <DisplayDateTime
                        data={ userDetail?.registration_date }
                        date
                      />
                    </div>
                  </div>

                  <div className="subscriber-detail-row">
                    <div>Country, City, State</div>
                    <div>
                      { userDetail?.addresses?.[ 0 ]?.city
                        ? userDetail?.addresses?.[ 0 ]?.country?.name +
                        ", " +
                        userDetail?.addresses?.[ 0 ]?.city +
                        ", " +
                        userDetail?.addresses?.[ 0 ]?.state
                        : "Not Provided Yet" }
                    </div>
                  </div>
                  <div className="subscriber-detail-row">
                    <div>Address</div>
                    <div>
                      { userDetail?.addresses?.[ 0 ]?.address_line
                        ? userDetail?.addresses?.[ 0 ]?.address_line
                        : "Not Provided Yet" }
                    </div>
                  </div>
                  <div className="subscriber-detail-row">
                    <div>Postal Code</div>
                    <div>
                      { userDetail?.addresses?.[ 0 ]?.postal_code
                        ? userDetail?.addresses?.[ 0 ]?.postal_code
                        : "Not Provided Yet" }
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <TitleComponent
                  title={ "Current Subscription" }
                  classNames="page-title sub-title"
                  handleEndAction={
                    <div className="subscription-status">{ checkStatus }</div>
                  }
                />
                { userDetail?.latest_subscription && (
                  <div className="subscriber-info-detail">
                    <div className="subscriber-detail-row">
                      <div>Plan Name</div>
                      <div>{ userDetail?.latest_subscription?.plan_name }</div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Plan Description</div>
                      <div>
                        { userDetail?.latest_subscription?.plan_description
                          ? userDetail?.latest_subscription?.plan_description
                          : "-" }
                      </div>
                    </div>
                    { userDetail?.latest_subscription?.trial_starts_at && (
                      <>
                        <div className="subscriber-detail-row">
                          <div>Trial Period</div>

                          <div>
                            { userDetail?.latest_subscription?.plan_slug
                              ?.trial_duration +
                              " " +
                              userDetail?.latest_subscription?.plan_slug
                                ?.trial_duration_type }
                          </div>
                        </div>
                        <div className="subscriber-detail-row">
                          <div>Trial Start Date</div>

                          <div>
                            <DisplayDateTime
                              data={
                                userDetail?.latest_subscription?.trial_starts_at
                              }

                            />
                          </div>
                        </div>
                        <div className="subscriber-detail-row">
                          <div>Trial End Date</div>

                          <div>
                            <DisplayDateTime
                              data={
                                userDetail?.latest_subscription?.trial_ends_at
                              }

                            />
                          </div>
                        </div>
                      </>
                    ) }
                    { userDetail?.latest_subscription?.package_starts_at && (
                      <>
                        <div className="subscriber-detail-row">
                          <div>Package</div>
                          <div>
                            { userDetail?.latest_subscription?.plan_price
                              ?.price +
                              userDetail?.latest_subscription?.plan_price
                                ?.currency?.symbol +
                              " / " +
                              userDetail?.latest_subscription?.plan_price
                                ?.package_duration +
                              " " +
                              userDetail?.latest_subscription?.plan_price
                                ?.package_duration_type }
                          </div>
                        </div>
                        <div className="subscriber-detail-row">
                          <div>Auto Renew</div>
                          <div>
                            { userDetail?.latest_subscription?.is_auto_renew === 1
                              ? "Enabled"
                              : "Disabled" }
                          </div>
                        </div>
                        <div className="subscriber-detail-row">
                          <div>Start Date</div>
                          <div>
                            <DisplayDateTime
                              data={
                                userDetail?.latest_subscription
                                  ?.package_starts_at
                              }

                            />
                          </div>
                        </div>
                        <div className="subscriber-detail-row">
                          <div>Expiry Date</div>
                          <div>
                            <DisplayDateTime
                              data={
                                userDetail?.latest_subscription?.package_ends_at
                              }

                            />
                          </div>
                        </div>
                        <div className="subscriber-detail-row">
                          <div>Coupon</div>
                          <div>
                            { userDetail?.latest_subscription?.coupon
                              ? userDetail?.latest_subscription?.coupon?.code
                              : "No Coupon Used" }
                          </div>
                        </div>
                        <div className="subscriber-detail-row">
                          <div>Total Paid</div>
                          <div>
                            {
                              userDetail?.latest_subscription?.itemable?.invoice
                                ?.total
                            }
                          </div>
                        </div>
                      </>
                    ) }
                      <div className="subscriber-detail-row">
                       <div className="label">Number of images</div>
                       <div className="value">{ `${ userUsage?.images?.used
                          } Out of  
                          ${ userUsage?.images?.allowed === -1
                            ? "Unlimited"
                            : userUsage?.images?.allowed
                          }` }</div>
                        </div>

                    <div className="subscriber-detail-row">
                      <div className="label">Number of sessions</div>
                      <div className="value">
                        { " " }
                        { `${ userUsage?.sessions?.used } Out of  
                        ${ userUsage?.sessions?.allowed === -1
                            ? "Unlimited"
                            : userUsage?.sessions?.allowed
                          }` }
                      </div>
                      </div>
                  </div>
                ) }
              </CardContent>
            </Card>
            { userDetail?.next_plan && (
              <Card>
                <CardContent>
                  <TitleComponent
                    title={ "Next Subscription" }
                    classNames="page-title sub-title"
                  />
                  <div className="subscriber-info-detail">
                    <div className="subscriber-detail-row">
                      <div>Plan Name</div>
                      <div>{ userDetail?.next_plan?.plan_name }</div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Plan Description</div>
                      <div>
                        { userDetail?.next_plan?.plan_description
                          ? userDetail?.next_plan?.plan_description
                          : "-" }
                      </div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Trial Start Date</div>

                      <div>
                        <DisplayDateTime
                          data={ userDetail?.next_plan?.trial_starts_at }

                        />
                      </div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Trial End Date</div>

                      <div>
                        <DisplayDateTime
                          data={ userDetail?.next_plan?.trial_ends_at }

                        />
                      </div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Package</div>

                      <div>
                        { userDetail?.next_plan?.plan_price?.price +
                          userDetail?.next_plan?.plan_price?.currency?.symbol +
                          " / " +
                          userDetail?.next_plan?.plan_price?.package_duration +
                          " " +
                          userDetail?.next_plan?.plan_price
                            ?.package_duration_type }
                      </div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Start Date</div>
                      <div>
                        <DisplayDateTime
                          data={ userDetail?.next_plan?.package_starts_at }

                        />
                      </div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Expiry Date</div>
                      <div>
                        <DisplayDateTime
                          data={ userDetail?.next_plan?.package_ends_at }

                        />
                      </div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Coupon</div>
                      <div>
                        { userDetail?.next_plan?.coupon
                          ? userDetail?.next_plan?.coupon?.code
                          : "No Coupon Used" }
                      </div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Total Paid</div>
                      <div>
                        { userDetail?.next_plan?.itemable?.invoice?.total }
                      </div>
                    </div>
                    <div className="subscriber-detail-row">
                      <div>Auto Renew</div>
                      <div>
                        { userDetail?.next_plan.is_auto_renew === 1
                          ? "Enabled"
                          : "Disabled" }
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) }
          </div>
        </>
      ) : (
        <Skeleton />
      ) }
      { openExtend && (
        <UsersExtendTrial
          onClose={ () => setOpenExtend( false ) }
          data={ userDetail }
          handleResponse={ () => GetData() }
        />
      ) }
    </>
  );
}

export default UsersDetail;
